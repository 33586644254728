<template>
    <div class="o-container">
        <app-intro>
            <h1>Selecteer uw functie(s)</h1>
            <h3>Waarvoor gaat u het bedrijfspand gebruiken?</h3>
        </app-intro>

        <loading v-bind:nodes="nodes">Bezig met laden van de functies...</loading>

        <transition-group class="c-grid-list" name="fade" tag="section">
            <card v-bind:card="node" :type="type" :index="index" v-for="node in nodes" :key="node.id"></card>
        </transition-group>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import AppIntro from '../components/app/AppIntro.vue';
    import Card from '../components/cards/Card.vue';
    import Loading from '../components/Loading.vue';
    import setStartingValues from '../mixins/setStartingValues';

    export default {
        mixins: [setStartingValues],
        components: {
            Loading,
            Card,
            AppIntro,
        },
        data () {
            return {
                type: 'checkbox',
                index: 'role',
            };
        },
        computed: {
            ...mapState({
                nodes: (state) => state.roles.all,
            }),
            ...mapGetters('summary', {
                summary: 'getSummaryByIndex',
            }),
        },
        async created () {
            // set current ab
            this.$store.commit('tabs/setCurrentTab', this.$route.name);

            // get role by style id
            const { id } = this.summary('style').find((item) => item);
            await this.$store.dispatch('roles/getSelected', id);

            // set starting values
            this.setStartingValues();
        },
        beforeRouteEnter (to, from, next) {
            next((vm) => {
                const { tabs } = vm.$store.state;
                const isDisabled = tabs.tabs[tabs.currentTab].disabled;

                if (isDisabled)
                {
                    const index = 'role';
                    const nextTabs = vm.$store.getters['tabs/getNextTabsFromIndex'](index);
                    vm.$store.dispatch('tabs/disableTabs', nextTabs);
                    vm.$store.dispatch('summary/resetSummary', [index]);
                    next('/');
                }
                else
                {
                    next();
                }
            });
        },
    };
</script>
